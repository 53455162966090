import * as base from "./base.js";
import * as until from "./until.js";
import axios from "axios";
// // 请求方式的封装
async function get(url, data, key,param) {
  return new Promise((resolve, reject) => {
    until.refresh()
    // 从缓存中获取
    var record = until.cacheSync("get", key);
    // 获取成功传出值
    if (key &&key!=''&& record && record!='') {
      resolve(record);
    } else {// 获取失败发起请求
      // 判断传参方式只有get判断
      if (typeof data === "string" || typeof data === "number") {
        url = url + "/" + data;
        data = {};
      }
      // 请求头
      var header = {};
      // 配置参数
      var obj = {
        headers: header,
        method: "get",
        url:  url,
        params: data,
      };
      if (param) {
        obj.url=obj.url+'/'+param
      }
      // 发起请求
      axios(obj)
        .then((res) => {
          // 传入key说明需要存入缓存
          if (key&&key!='') {
            until.cacheSync("set", key, res.data);
          }
          // 传出数据
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}

async function post(url, data,type) {
  return new Promise((resolve, reject) => {
    // 请求头

    var header = {};
    var obj = {
      headers: header,
      method: "post",
      url:  url,
    };
    if (type&&type == 'params') {
      obj.params=data
    }else{
      obj.data=data
    } 
    axios(obj)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function logn(url, data,params) {
  console.log(" login data ", data)
  console.log(" login params ", params)
  
  return new Promise((resolve, reject) => {
    // 请求头

    var header = {
      isToken: false,
      'TENANT-ID': '2',  //门户用户， tenant_id=2
      'Authorization': 'Basic cG9ydGFsOnBvcnRhbA==',  //portal:portal  client_id:portal
    };
    var obj = {
      headers: header,
      method: "post",
      url:  url,
      params: params,
      data:data
    };
    
    axios(obj)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err)
      });
  });
}

async function lout(url, data,params) {
  return new Promise((resolve, reject) => {
    // 请求头

    var header = {
      isToken: false,
      'TENANT-ID': '2',  //门户用户， tenant_id=2
      'Authorization': 'Basic cG9ydGFsOnBvcnRhbA==',  //portal:portal  client_id:portal
    };
    var obj = {
      headers: header,
      method: "post",
      url:  url,
      params: params,
      data:data
    };
    
    axios(obj)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err)
      });
  });
}
// 获取行业类型
export const getCaseType = () => get('/cms/datadictionary/page?current=1&size=100&dataType=行业类型');

// 获取检测类型
export const getTestType = () => get('/cms/datadictionary/page?current=1&size=100&dataType=检测类型');

// 获取在线检测工具列表(首页使用)
export const getCmsInfoTop = (category, data) => get('/cms/cmsinfo/'+category+'/top', data);
export const getCmsInfoPage = (category, data) => get('/cms/cmsinfo/'+category+'/page', data);
export const getCmsInfoDetail = (category, id) => get('/cms/cmsinfo/'+category+'/'+id);


// 获取数据集和模型列表(首页使用)
export const getDataset = (data) => get(base.getDatasetApi, data);
export const getDatasetDetail = (id) => get(base.getDatasetDetailApi+"/"+id);
export const getDatasetDict = (type) => get(base.getDatasetDictApi+"/"+type);

// 获取在线检测工具列表(首页使用)
export const getToolTop = (data) => get(base.toolTopApi, data);
export const getToolPage = (data) => get(base.toolPageApi, data);
export const getToolDetail = (id) => get(base.toolDetailApi+id);


// 获取行业应用检验检测列表
export const getInspectTop = (data) => get(base.inspectTopApi, data);
export const getInspectPage = (data) => get(base.inspectPageApi, data);
export const getInspectDetail = (data) => get(base.inspectDetailApi, data);

// 获取轮播图列表(首页使用)
export const getCarouselList = (data) => get(base.getCarouselListApi, data);
export const getNewsTop = (data) => get(base.getNewTopApi, data);
export const getNewsList = (data) => get(base.getNewListApi, data);
export const getNewsDetail = (data) => get(base.getNewsDetailApi, data);

// 获取标准资源列表(首页使用)
export const getStandardTop = (data) => get(base.standardTopApi, data);
export const getStandardPage = (data) => get(base.standardPageApi, data);
export const getStandardDetail = (data) => get(base.standardDetailApi, data);


// 获取知识产权列表(首页使用)
export const getPatentTop = (data) => get(base.patentTopApi, data);
export const getPatentPage = (data) => get(base.patentPageApi, data);
export const getPatentDetail = (data) => get(base.patentDetailApi, data);


// 获取行业应用检测案例列表(列表页)
export const getCaseTop = (data) => get(base.caseTopApi, data);
export const getCasePage = (data) => get(base.casePageApi, data);
export const getCaseDetail = (data) => get(base.caseDetailApi, data);


// 获取产业分析报告列表(列表页)
export const getAnalyseTop = (data) => get(base.analyseTopApi, data);
export const getAnalysePage = (data) => get(base.analysePageApi, data);
export const getAnalyseDetail = (data) => get(base.analyseDetailApi, data);

// 获取培训列表(列表页)
export const getTrainTop = (data) => get(base.trainTopApi, data);
export const getTrainPage = (data) => get(base.trainPageApi, data);
export const getTrainDetail = (data) => get(base.trainDetailApi, data);

// 获取咨询列表(列表页)
export const getConsultTop = (data) => get(base.consultTopApi, data);
export const getConsultPage = (data) => get(base.consultPageApi, data);
export const getConsultDetail = (data) => get(base.consultDetailApi, data);

// 获取基础信息列表(列表页)
export const getInfoTop = (data) => get(base.infoTopApi, data);
export const getInfoPage = (data) => get(base.infoPageApi, data);
export const getInfoDetail = (data) => get(base.infoDetailApi, data);

//用户检测列表
export const getUserToolTest = (data, uid) => get(base.userToolTestApi+"/"+uid,data);
export const getToolTestDetail = ( tid) =>   get(base.userToolTestDetailApi +"/"+ tid);

export const getToolsDetail = (data) => get(base.getToolsDetailApi, data);
export const getUserIndustryTest = ( uid, data) => get(base.getUserIndustryTestlApi +"/" + uid, data);
export const getIndustryTestDetail = (tid) => get(base.getIndustryTestlDetailApi +"/" + tid);


//获取验证图片  以及token
export const getCode = (data) => get(base.getCodeApi, data);
  //滑动或者点选验证
export const checkCode = (data) => post(base.checkCodeApi, data, 'params');
 //用户进行登录
export const login = (data, params) => logn(base.loginApi, data, params);
 //用户进行登录
export const logout = (data, params) => lout(base.logoutApi, data, params);

// 用户名进行注册(注册页) 
export const register = (data) => post(base.registerApi, data);
// 判断用户名是否重复(注册页) 
export const checkName = (data) => get(base.checkNameApi, data);
// 判断电子邮件是否重复(注册页) 
export const checkEmail = (data) => get(base.checkEmailApi, data);
// 判断手机号是否重复(注册页) 
export const checkMobile = (data) => get(base.checkMobileApi, data);
// 判断手机号是否重复(注册页) 
export const checkPhone = (data) => get(base.checkPhoneApi, data);
export const sendEmailCode = (data) => post(base.sendEmailCodeApi, data);
export const checkEmailCode = (data) => post(base.checkEmailCodeApi, data);
export const resetpwd = (data) => post(base.resetpwdApi, data);
export const addSuggestion = (data) => post(base.addSuggestionApi, data);

// 获取用户信息
export const getUserInfo = (data) => get(base.userInfoApi, data);
// 获取用户信息
export const updateUser = (data) => post(base.updateUserApi, data);
// 修改密码
export const updatePwd = (data) => post(base.updatePwdApi, data);

// 行业应用检测申请
export const addInspectOnline = (data) => post(base.addInspectOnlineApi, data);


// // 获取数据集和模型分类： 输入参数 、基础技术领域资源库、专项解决方案资源库
// export const getresourceDictBase = () => get(base.getresourceDictApi+"/基础技术领域资源库");
// export const getresourceDictIndustry = () => get(base.getresourceDictApi+"/行业领域资源库");
// export const getresourceDictSpec = () => get(base.getresourceDictApi+"/专项解决方案资源库");

// // 获取模型列表
// export const getmodel = (data) => get(base.getmodelApi, data);
// // 获取数据集和模型详情





// // 获取检测工具列表(首页使用)
// export const tesTools = (data) => get(base.tesToolsApi, data);
// // 获取知识产权列表(首页使用)
// export const intellProp = (data) => get(base.intellPropApi, data);
// // 获取产业分析列表(首页使用)
// export const industryReport = (data) => get(base.industryReportApi, data);


// // 获取知识产权详情
// export const intellPropdetail = (data) => get(base.intellPropdetailApi, data);
// // 获取产业分析详情
// export const industrydetail = (data) => get(base.industrydetailApi, data);
// // 获取数据集和模型列表(首页使用)
// export const getresource = (data) => get(base.getresourceApi, data);

// // 获取数据集和模型分类： 输入参数 、基础技术领域资源库、专项解决方案资源库
// export const getresourceDictBase = () => get(base.getresourceDictApi+"/基础技术领域资源库");
// export const getresourceDictIndustry = () => get(base.getresourceDictApi+"/行业领域资源库");
// export const getresourceDictSpec = () => get(base.getresourceDictApi+"/专项解决方案资源库");

// // 获取模型列表
// export const getmodel = (data) => get(base.getmodelApi, data);
// // 获取数据集和模型详情
// export const getmodeldetail = (data) => get(base.getmodeldetailApi, data);
// // 获取人工智能基础信息列表(首页使用)
// export const getAinfoList = (data) => get(base.getAinfoListApi, data);
// // 获取行业应用检测案例列表(列表页)
// export const detionList = (data) => get(base.detionListApi, data);
// // 获取基础信息(详情页) 

// // 获取行业应用检测案例(详情页) 
// export const getInspect = (data) => get(base.getInspectApi, data);
// // 获取基础信息列表(详情页) 
// export const getBasicsDetail = (data) => get(base.getBasicsDetailApi, data);
// // 获取知行业应用检测目录列表(列表页) 
// export const getAbilityList = (data) => get(base.getAbilityListApi, data);
// // 获取知行业应用检测目录列表(列表页) 
// export const getTrainList = (data) => get(base.getTrainListApi, data);
// // 获取人工智能咨询服务导航列表(列表页) 
// export const getQuestionList = (data) => get(base.getQuestionListApi, data);
// // 获取人工智能检测分类列表(列表页) 
// export const getTestList = (data) => get(base.getTestListApi, data);
// // 获取人工智能检测检测详情(详情页) 
// export const getTestDetail = (data) => get(base.getTestDetailApi, data);

// // 获取角色列表进行注册(注册页) 
// export const getRoleList = (data) => get(base.getRoleListApi, data);
// // 获取检测工具列表(列表页) 
// export const getToolsList = (data) => get(base.getToolsListApi, data);
// // 获取检测工具列表(详情页) 



