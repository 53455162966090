// 所有的接口地址以及请求头
// export const baseUrl = "http://192.168.1.234";
// export const imgBaseUrl = "http://192.168.1.234/";

export const baseUrl = "";
export const imgBaseUrl = "/";


// 获取数据集和模型列表
export const getDatasetApi = `/data-repository-api/api/repo/all/list`;
// 获取数据集和模型详情
export const getDatasetDetailApi = `/data-repository-api/api/repo`;
// 获取数据集字典
export const getDatasetDictApi = `/data-repository-api/api/repo/dict/query`;


// 获取在线工具列表
export const toolTopApi = `/tool/config/top`; //-----
export const toolPageApi = `/tool/config/page`; //-----
export const toolDetailApi = `/tool/config/`; //-----


// 获取行业应用检验检测列表
export const inspectTopApi = `/cms/inspect/top`; //-----
export const inspectPageApi = `/cms/inspect/page`; //-----
export const inspectDetailApi = `/cms/inspect`; //-----


// 获取轮播图列表
export const getCarouselListApi = `/cms/news/carousel`; //-----
export const getNewTopApi = `/cms/news/top`; //-----
export const getNewListApi = `/cms/news/page`; //-----

// 获取标准资源列表
export const standardTopApi = `/cms/standardresource/top`; //-----
export const standardPageApi = `/cms/standardresource/page`; //-----
export const standardDetailApi = `/cms/standardresource`; //-----

// 获取标准资源列表
export const patentTopApi = `/cms/patentreport/top`; //-----
export const patentPageApi = `/cms/patentreport/page`; //-----
export const patentDetailApi = `/cms/patentreport`; //-----


// 获取行业应用案例列表
export const caseTopApi = `/cms/case/top`; //-----
export const casePageApi = `/cms/case/page`; //-----
export const caseDetailApi = `/cms/case`; //-----

// 获取产业分析报告列表
export const analyseTopApi = `/cms/industryreport/top`; //-----
export const analysePageApi = `/cms/industryreport/page`; //-----
export const analyseDetailApi = `/cms/industryreport`; //-----


// 获取培训列表
export const trainTopApi = `/cms/train/top`; //-----
export const trainPageApi = `/cms/train/page`; //-----
export const trainDetailApi = `/cms/train`; //-----


// 获取咨询列表
export const consultTopApi = `/cms/consult/top`; //-----
export const consultPageApi = `/cms/consult/page`; //-----
export const consultDetailApi = `/cms/consult`; //-----


// 获取基础信息列表
export const infoTopApi = `/cms/basicinfo/top`; //-----
export const infoPageApi = `/cms/basicinfo/page`; //-----
export const infoDetailApi = `/cms/basicinfo`; //-----

// 获取模型列表
export const getmodeldetailApi2 = `/data-repository-api/api/repo`;
export const getresourceApi2 = `/data-repository-api/api/repo/all/list`;
export const getresourceDictApi2 = `/data-repository-api/api/repo/dict/query`;


// 用户信息
export const userInfoApi = `/admin/portaluser/info`;

// 更新用户信息
export const updateUserApi = `/admin/portaluser/updateUser`;
export const updatePwdApi = `/admin/portaluser/pwd`;

// 判断用户名是否重复(注册页)
export const checkNameApi = `/admin/portaluser/checkname`;
export const checkEmailApi = `/admin/portaluser/checkemail`;
export const checkMobileApi = `/admin/portaluser/checkmobile`;
export const checkPhoneApi = `/admin/portaluser/checkphone`;
export const sendEmailCodeApi = `/admin/portaluser/sendemailcode`;
export const checkEmailCodeApi = `/admin/portaluser/checkemailcode`;
export const resetpwdApi = `/admin/portaluser/resetpwd`;

export const addSuggestionApi=`/cms/suggestion`;
// 用户名进行注册(注册页)
export const registerApi = `/admin/portaluser`;

// 行业应用检测申请
export const addInspectOnlineApi  = `/cms/test/industry`;


// 获取检测工具列表
export const tesToolsApi = `/tool/config/top6`; //----------
// 获取知识产权列表
export const intellPropApi = `/cms/patentreport/page`; //---------
// 获取知识产权详情
export const intellPropdetailApi = `/cms/patentreport`; //---------
// 获取产业分析列表
export const industryReportApi = `/cms/industryreport/page`; //--------patentreport-
// 获取产业分析详情
export const industrydetailApi = `/cms/industryreport`; //----------


// 获取数据集和模型列表
export const getmodelApi = `/data-repository-api/api/repo/dataset/list`;
// 获取数据集和模型详情
export const getmodeldetailApi = `/data-repository-api/api/repo`;
// 获取模型列表
//export const getresourceApi = `/data-repository-api/api/repo/model/list`;
export const getresourceApi = `/data-repository-api/api/repo/all/list`;

export const getresourceDictApi = `/data-repository-api/api/repo/dict/query`;
// 获取人工智能基础信息列表
export const getAinfoListApi = `/cms/basicsinfo/page`; //-----------
// 获取行业应用检测案例列表(列表页)
export const detionListApi = `/cms/inspectcase/page`; //--------------
// 获取检测工具列表(列表页)
export const getToolsListApi = `/tool/config/page`; //----------
// 获取检测工具列表(详情页)
export const getToolsDetailApi = `/tool/config`; //----------
// 获取公告信息(详情页)
export const getNewsDetailApi = `/cms/news`; //--------
// 获取行业应用检测案例(详情页)
export const getInspectApi = `/cms/inspectcase`; //---------
// 获取基础信息列表(详情页)
export const getBasicsDetailApi = `/cms/basicsinfo`; //------
// 获取知行业应用检测目录列表(列表页)
export const getAbilityListApi = `/cms/inspectability/page`; //------------
// 获取知行业应用检测目录列表(列表页)
export const getTrainListApi = `/cms/trainedonlineb/page`; //--------
// 获取人工智能咨询服务导航列表(列表页)
export const getQuestionListApi = `/cms/consultknowledge/page`; //--------
// 获取人工智能检测分类列表(列表页)
export const getTestListApi = `/portalserver/sampleTestAsk/getList`;
// 获取人工智能检测检测详情(详情页)
export const getTestDetailApi = `/portalserver/sampleTestAsk/id`;
// 获取角色列表进行注册(注册页)
export const getRoleListApi = `/admin/role/list`;
//获取验证图片  以及token
export const getCodeApi = `/code`;
//滑动或者点选验证
export const checkCodeApi = `/code/check`;
//用户进行登录
export const loginApi = `/auth/oauth/token`;
export const logoutApi = `/auth/token/logout`;

//用户工具检测列表
export const userToolTestApi = `/tool/test/user`;
export const userToolTestDetailApi = `/tool/test`;

export const getIndustryTestlApi = `/cms/test/industry/page`;
export const getUserIndustryTestlApi = `/cms/test/industry/user`;
export const getIndustryTestlDetailApi = `/cms/test/industry`;

