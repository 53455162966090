import Vue from "vue";
import md5 from 'js-md5';
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "../postcss.config.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import * as base from "./config/base.js";
import * as api from "./config/api.js";
import * as until from "./config/until.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });
Vue.prototype.$md5=md5; 

import QuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(QuillEditor)

import * as filters from '@/util/filter'
// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$base = base;
Vue.prototype.$api = api;
Vue.prototype.until = until;

Vue.config.productionTip = false;
Vue.use(ElementUI);

if (typeof String.prototype.endWith != 'function') {
  String.prototype.endWith = function (str){
     return this.slice(-str.length) == str;
  };
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

