import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [ 
  {
    path: "/register", //注册
    name: "register",
    component: () =>
      import(/* webpackChunkName: "seatinfo" */ "../views/register/index.vue"),
  }, 
  {
    path: "/resetpwd", //注册
    name: "resetpwd",
    component: () =>
      import(/* webpackChunkName: "seatinfo" */ "../views/register/resetpwd.vue"),
  },
  {
    path: "/login", //注册
    name: "login",
    component: () =>
      import(/* webpackChunkName: "seatinfo" */ "../views/login/index.vue"),
  },  
  {
    path: "/dev/dataset/", //请登录
    name: "devds",
    component: () => import( "../views/dev/dataset/index.vue"),
  },  
  {
    path: "/dev/dataset/detail/:id", //检测工具的详情页
    name: "devDatasetDetail",
    component: () =>import("../views/dev/dataset/datasetDetail.vue"),
  },   

  {
    path: "/",
    redirect: "/home", // 通过 redirect是属性，可以实现前端路由的重定向 如果访问的是 根路径 就会跳转到指定路径
  },
 
  {
    path: "/tologin", //请登录
    component: Layout,
    redirect: "/tologin/index",
    children: [
      {
        path: "index",
        name: "tologin",
        component: () => import( "../views/home/tologin/index.vue"),
      }
    ]
  },   
  {
    path: "/home",
    component: Layout,
    redirect: "/home/index",
    children: [
      {
        path: "index",
        name: "news",
        component: () =>  import("../views/home/index.vue"),
      },
    ]
  },
  {
    path: "/news",
    component: Layout,
    redirect: "/news/index",
    children: [
      {
        path: "index",
        component: () => import( "../views/news/index.vue"),
        redirect: "/news/list/0",
        children:[
          {
            path: "/news/list/:newsTag",
            name: "newsList",
            component: () => import( "../views/news/newsList.vue"),
          },
          {
            path: "/news/detail/:id", //公告信息的详情页
            name: "newsDetail",
            component: () =>
              import(/* webpackChunkName: "noticcontent" */ "../views/news/newsDetail.vue"),
          },
        ]
      },   
      // {
      //   path: "/news/detail/:id", //公告信息的详情页
      //   name: "newsDetail",
      //   component: () =>
      //     import(/* webpackChunkName: "noticcontent" */ "../views/news/newsDetail.vue"),
      // },
    ]
  },
    
  {
    path: "/inspect",
    component: Layout,
    redirect: "/inspect/index",
    children: [
      {
        path: "index",
        component: () => import( "../views/inspect/index.vue"),
        redirect: "/inspect/tool",
        children:[
          {
            path: "/inspect/statistic",
            component: () =>  import("../views/inspect/statistic.vue"),
          },
          {
            path: "/inspect/tool",
            component: () => import( "../views/tool/toolList.vue"),
          },
          // {
          //   path: "/inspect/tool/detail/:id", 
          //   component: () =>
          //     import( "../views/tool/toolDetail.vue"),
          // },
          {
            path: "/inspect/industry",
            component: () => import( "../views/industry/industryList.vue"),
          },
          {
            path: "/inspect/industry/detail/:id", 
            component: () =>
              import(/* webpackChunkName: "noticcontent" */ "../views/industry/industryDetail.vue"),
          },
          {
            path: "/inspect/industry/apply/:id", 
            component: () =>
              import( "../views/industry/industryApply.vue"),
          },
        ]
      },
      {
        path: "/inspect/tool/detail/:id", 
        component: () =>
          import( "../views/tool/toolDetail.vue"),
      },
    ]
  },

  {
    path: "/show",
    component: Layout,
    redirect: "/show/index",
    children: [
      {
        path: "index",
        component: () => import( "../views/show/index.vue"),
        redirect: "/show/compliance",
        children:[
          {
            path: "/show/compliance",
            component: () =>  import("../views/show/complianceList.vue"),
          },
          {
            path: "/show/compliance/detail/:id", //标准的详情页
            name: "complianceDetail",
            component: () =>
              import("../views/show/complianceDetail.vue"),
          },
          {
            path: "/show/case",
            component: () =>  import("../views/show/caseList.vue"),
          },
          {
            path: "/show/case/detail/:id", //标准的详情页
            name: "complianceDetail",
            component: () =>
              import("../views/show/caseDetail.vue"),
          },
          
        ]
      },
    ]
  },
  {
    path: "/dataset",
    component: Layout,
    redirect: "/dataset/index",
    children: [
      {
        path: "index",
        name: "dataset",
        component: () => import( "../views/dataset/index.vue"),
        redirect: "/dataset/list/0", 
        // redirect: "/dataset/list/基础技术领域资源库", 
        children:[
          {
            path: "/dataset/list/:type",
            name: "ds0",
            component: () => import( "../views/dataset/datasetSubList.vue"),
          },
          {
            path: "/dataset/detail/:id", //检测工具的详情页
            name: "datasetDetail",
            component: () =>
              import("../views/dataset/datasetDetail.vue"),
          },
          // {
          //   path: "/dataset/list/基础技术领域资源库",
          //   name: "ds1",
          //   component: () => import( "../views/dataset/datasetSubList.vue"),
          // },
          // {
          //   path: "/dataset/list/行业领域资源库",
          //   name: "ds2",
          //   component: () => import( "../views/dataset/datasetSubList.vue"),
          // },
          // {
          //   path: "/dataset/list/专项解决方案资源库",
          //   name: "ds3",
          //   component: () => import( "../views/dataset/datasetSubList.vue"),
          // },
        ]
      },
      
    ]
  },
  
  {
    path: "/resource",
    component: Layout,
    redirect: "/resource/index",
    children: [
      {
        path: "index",
        component: () => import( "../views/resource/index.vue"),
        redirect: "/resource/standard",
        children:[
          {
            path: "/resource/statistic",
            component: () =>  import("../views/resource/statistic.vue"),
          },
          {
            path: "/resource/standard",
            component: () =>  import("../views/standard/standardList.vue"),
          },
          {
            path: "/resource/standard/detail/:id", //标准的详情页
            name: "standardDetail",
            component: () =>
              import("../views/standard/standardDetail.vue"),
          },
          {
            path: "/resource/patent",
            component: () =>  import("../views/patent/patentList.vue"),
          },
          {
            path: "/resource/patent/detail/:id", //知识产权的详情页
            component: () =>  import("../views/patent/patentDetail.vue"),
          },
          {
            path: "/resource/case",
            component: () =>  import("../views/case/caseList.vue"),
          },
          {
            path: "/resource/case/detail/:id", //案例的详情页
            component: () =>  import("../views/case/caseDetail.vue"),
          },
          {
            path: "/resource/analyse",
            component: () =>  import("../views/analyse/analyseList.vue"),
          },
          {
            path: "/resource/analyse/detail/:id", //分析报告的详情页
            component: () =>  import("../views/analyse/analyseDetail.vue"),
          },
          {
            path: "/resource/consult",
            component: () =>  import("../views/consult/consultList.vue"),
          },
          {
            path: "/resource/consult/detail/:id", //咨询详情页
            component: () =>  import("../views/consult/consultDetail.vue"),
          },
          {
            path: "/resource/info",
            component: () =>  import("../views/basicinfo/infoList.vue"),
          },
          {
            path: "/resource/info/detail/:id", //基础信息的详情页
            component: () =>  import("../views/basicinfo/infoDetail.vue"),
          },
        ]
      },
    ]
  },
  {
    path: "/train",
    component: Layout,
    redirect: "/train/index",
    children: [
      {
        path: "index",  //行业应用案例的列表页
        name: "train",
        component: () => import( "../views/train/index.vue"),
        redirect: "/train/list",
        children:[
          {
            path: "/train/statistic",
            component: () =>  import("../views/train/statistic.vue"),
          },
          {
            path: "/train/list",
            component: () =>  import("../views/train/trainList.vue"),
          },
          {
            path: "/train/detail/:id",
            component: () =>  import("../views/train/trainDetail.vue"),
          },
        ]
      },
    ]
  },
  
  {
    path: "/aisbench",
    component: Layout,
    redirect: "/aisbench/index",
    children: [
      {
        path: "index",  //行业应用案例的列表页
        name: "aisbench",
        component: () =>  import("../views/aisbench/aisBench.vue"),
      }
    ]
    
  },
  {
    path: "/about",
    component: Layout,
    redirect: "/about/index",
    children: [
      {
        path: "index",  //行业应用案例的列表页
        name: "aboutindex",
        component: () => import( "../views/about/index.vue"),
        redirect: "/about/introduct",
        children:[
        {
          path: "/about/introduct",  //行业应用案例的列表页
          name: "introduct",
          component: () =>  import("../views/about/introduct.vue"),  
        },
        {
          path: "/about/suggest",  //行业应用案例的列表页
          name: "suggest",
          component: () =>  import("../views/about/suggest.vue"),  
        },
      ]
    }]
  },
  {
    path: "/user",
    component: Layout,
    redirect: "/user/index",
    children: [
      {
        path: "index",  //行业应用案例的列表页
        component: () => import( "../views/user/index.vue"),
        redirect: "/user/info",
        children:[
          {
            path: "/user/info",
            component: () =>  import("../views/user/userinfo.vue"),
          },
          {
            path: "/user/pwd",
            component: () =>  import("../views/user/resetPassword.vue"),
          },
        ]
      },
      {
        path: "/mytest/tool/detail/:id", //行业应用案例的详情页
        component: () =>
          import("../views/mytest/toolTestDetail.vue"),
      },
    ]
  },
  
]


const router = new VueRouter({
  // mode: 'history',
  routes
})
export default router;
