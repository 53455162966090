<template>
  <div id="homebox">
    <div id="pagebox" class="flexspb navgon">
      <div
        class="menu  flexfvc hideSubmenu"
        :class="{ unselect:   primary != index , 'select':  primary == index}"
        @click.stop="changenar(index, item.path)"
        @mouseover.stop="showSubmenu" 
        @mouseleave.stop="hideSubmenu" 
        v-for="(item, index) in list"
        :key="index"
      > 
        <div class="menuItem  flexcent" >
          {{ item.name }}
        </div>
      
        <div class="submenu  flexve" v-if="item.submenu" >
          <div class="sub-item flexcent "
            @click.stop="changeSubmenu(ids, ems.path, item.path, index, $event)"
            v-for="(ems, ids) in item.submenu"
            :key="ids"
          >
            <span class="title" >{{ ems.name }}</span>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation", //导航栏
  props: {
    //导航栏数组
    list: {
      typeof: [Array],
    },
    // 当前的页码(1级菜单)
    // pagenum: {
    //   typeof: [Number],
    //   default: 0,
    // },
  },
  data() {
    return {
      primary: 0, //一级菜单的选中样式

    };
  },
  watch:{
    $route:{
      handler(){
        // console.log(" ===========  route changed " );//新路由信息
        this.init()
      },
      // 深度观察监听
      deep: true
    }
  }, 
  // 进入页面同步数据
  created() {
    // this.primary = this.pagenum; //同步一级菜单
    this.init()
  },
  methods: {
    init(){
      var path = this.$route.path;
      this.primary = -1
      for(var i =0; i<this.list.length;i++) {
        // console.log(i +"  " + this.list[i].path)
        // console.log( path.indexOf(this.list[i].path) )
        // console.log(  " 0 == " , path.indexOf(this.list[i].path) )
        if(path.indexOf(this.list[i].path)==0) {
          this.primary = i;
          break;
        }
      }
      // console.log("============ route   ", path  )
      // console.log(" current   ", this.primary  )
    },
    changeSubmenu(index, subpath, parentPath, parentIndex, event){
      // console.log(" event.currentTarget ", event.currentTarget)

      let cls = event.currentTarget.parentElement.parentElement.classList
     // console.log("-------- change submenu cls",cls)
      cls.remove("showSubmenu")
      cls.add("hideSubmenu")
      //console.log(" hide submenu cls",cls)
      

      // var subMenuDiv = event.currentTarget.parentElement
      // console.log(" subMenuDiv style ", subMenuDiv.style.visibility)
      // subMenuDiv.style.visibility = 'hidden'
      // console.log(" subMenuDiv style ", subMenuDiv.style.visibility)
      // subMenuDiv.style.visibility = ''
      // console.log(" event.parent ", event.currentTarget.parentElement)
    
      // submenus.style.visibility = 'hidden'
  
      let toPath = parentPath + subpath
      //  进行路由跳转
      // if (this.$route.path != toPath) {
        this.primary = parentIndex;      
        this.until.jump.call(this, toPath, "push", {});
      // }
    },
    // 点击切换菜单样式
    changenar(index, path) {
      // console.log( " index ", index)
      // console.log( " path ", path)
      //  进行路由跳转
      if (this.$route.path != path) {
        this.primary = index;
        this.until.jump.call(this, path, "push", {});
      }
    },
    showSubmenu(event){
      // console.log(" enter1 ", event)
      // console.log(" list  ", event.currentTarget.classList)
      // console.log(" class ", event.currentTarget.getAttribute("class"))

      let cls = event.currentTarget.classList
      cls.remove("hideSubmenu")
      cls.add("showSubmenu")
 
      // console.log(" showSubmenu ", cls)


    },
    hideSubmenu(event){
      // console.log(" leve ", event)
      let cls = event.currentTarget.classList
      
      cls.remove("showSubmenu")
      cls.add("hideSubmenu")
 
      // console.log(" hideSubmenu ",cls)
    },
    
  },
};
</script>

<style scoped lang="less">
@menu_width: 150px;
@menu_height: 50px;
@submenu_top: 130px;
@fontsize: 18px;
@sub-fontsize: 14px;
@color: #fff;
@select-color: #ff9150;
@sub-background-color: #27699b;
  // 当前页面
  #homebox {
    height: @menu_height;
    background-color: #16499b;

    // div{
    //    border: 1px solid #f00;
    // }
    // 导航栏
    .navgon {
      justify-content: flex-start;
      align-items: stretch;
      font-size: @fontsize;
      color: #fff;

      // 每一项内容
      .menu {
        // border: 1px solid #f00;
        align-items: center;
        text-align:center;
        // font-weight: 500;
        // padding: 0 10px;
        white-space: nowrap;
        min-height: @menu_height;
        width: @menu_width;
        // width: 100%;
        .menuItem{
          min-height: @menu_height;
          width: 100%;
        }
        .submenu {
          color: @color;
          z-index: 1000;
          visibility: hidden;
          position: absolute;
          top: @submenu_top;
          width: @menu_width;
          font-size: @sub-fontsize;
          background-color: @sub-background-color;
          z-index: 0;
          .sub-item {
            height: 50px;
            border-bottom: 1px solid #ececec;
          }
          .sub-item:hover{
             background-color: #3a7cf7;
          }
        }
      }
      .showSubmenu {
        cursor: pointer;
        color: @select-color;
        // font-weight: 550;
        background-color: #133A80;
        .submenu {
          visibility: visible;
           z-index: 1000;
        }
      }
      .hideSubmenu {
        cursor: pointer;
        color: @color;
        // font-weight: 550;
        .submenu {
          color: @color;
          z-index: 0;
          visibility: hidden;
        }
      }

      // // 每一项内容
      // .unselect:hover {
      //   cursor: pointer;
      //   color: #ff9150;
      //   // font-weight: 550;
      //   background-color: #133A80;
      //   .submenu {
      //     color: #fff;
      //     z-index: 1000;
      //     visibility: visible;
      //   }
      // }
      // .select:hover {
      //   cursor: pointer;
      //   color: #ff9150;
      //   // font-weight: 550;
      //   background-color: #133A80;
      //   .submenu {
      //     color: #fff;
      //     z-index: 1000;
      //     visibility: visible;
      //   }
      // }
      // .menu:active {
      //   // border: 2px solid rgb(200, 255, 0);
      //   // .submenu {
      //   //   visibility: hidden;
      //   // }
      // }
      .select {
        color: @select-color;
        // font-weight: 550;
        border-bottom: 2px solid @select-color;
      }
      // 一级菜单的选中状态
      
    }
  }
@media screen and (min-width: 1000px) {}
</style>
