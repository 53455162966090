<template>
  <!-- 导航栏(传入数组) -->
  <div class="footbox">
    <!-- 尾部导航 -->
    <!-- <div>
      <tailtal :list="list"/>
    </div> -->
    <!-- 尾部备案 -->
    <div>
      <foootxet />
    </div>
  </div>
</template>

<script>
// import tailtal from "./tailtal";
import foootxet from "./foootxet";

export default {
  name: "footerbox", //尾部
  components: {
    // tailtal, //尾部导航
    foootxet, //尾部信息
  },
  data() {
    return {
      //导航栏数组
      list: [
        {
          name: "行业案例",
          path: "", //二级菜单
          list: [
            "工业制造",
            "物流行业",
            "医疗健康",
            "交通行业",
            "安防行业",
            "金融行业",
            "智能家居",
          ],
        },
        {
          name: "在线资源",
          path: "", //二级菜单
          list: [
            "标准资源",
            "数据集和模型",
            "产业分析报告",
            "知识产权分析报告",
          ],
        },
        {
          name: "公共服务",
          path: "", //二级菜单
          list: [
            "人工智能检测服务",
            "行业应用检测服务",
            "标准研制服务",
            "人工智能培训服务",
            "知识产权分析服务",
            "人工智能咨询服务",
          ],
        },
        {
          name: "基础信息",
          path: "", //二级菜单
          list: ["研究成果", "基础技术", "智能应用"],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">

  .footbox {
    position: relative;
    // background-color: #3d3e49;
    background-color: rgb(25, 42, 80);
    z-index: 400;
  }

// @media screen and (min-width: 1000px) { }

// @media screen and (max-width: 999px) {
// }
</style>
