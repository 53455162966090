const userKey = "aitp.user"
const userExipredKey = "aitp.user.expired"
const tokenKey = "aitp.access_token"
const refreshTokenKey = "aitp.refresh_token"
const expireDuration = 1*60*60*1000 //毫秒
// const expireDuration = 40000 //毫秒
const refreshDuration = expireDuration/2 //毫秒

var exipredTime = 0;
var refreshTime = 0;
var initialed = false;
var doLogin = false;
var userInfo  = null

function initial(){
  // debugger
  if(initialed) {
    if( !userInfo) {
      var userStr2  = localStorage.getItem(userKey)
      if(userStr2 && userStr2!='') {
        userInfo = JSON.parse(userStr2);
      } else {
        userInfo = null
      }
      // console.log("============== set userInfo =" , userInfo)
      
    }
    // console.log("  ----  already initialed "+ initialed )
    // info()
    return true
  }
  // console.log("  ----  first initial "+ initialed )
  initialed = true
  // console.log("  ======== set initialed = true ")
  var expired = getExpiredTime()
  var currTime = new Date().getTime()
  // console.log(" initial  exipredTime ", expired)
  // console.log(" initial     currTime ", currTime)
  if(currTime > expired){
    // console.log("  ---- inital  check  expired ,clean user info ")
    // debugger
    clearUserInfo()
    info()
    
    return true;
  }

  var userStr  = localStorage.getItem(userKey)
  // console.log("  ---- inital  check  no expired ,  get user ", userStr)
  if(userStr && userStr!='') {
    userInfo = JSON.parse(userStr);
    // console.log("  ======== set userInfo  ", userInfo)
  } else {
    // console.log(" =========  has not userInfo ")
  }
    
  // console.log("  --------  inital --------")
  info()
  return true
}

function info(){
  console.log(" initialed  = " + initialed +", doLogin = "+ doLogin +" ,  exipredTime=" + exipredTime +", refreshTime=" + refreshTime +",  expired :" +  ( new Date().getTime()>exipredTime) )
  console.log(" currenttime=" + new Date().getTime())
  console.log( "     storage  userExipredKey " +  localStorage.getItem(userExipredKey) )
  console.log( "     storage  userKey " +  localStorage.getItem(userKey) )
  console.log( "             userInfo " , userInfo)

  //console.log( "     storage  user    " + localStorage.getItem(userKey) )
}
function isLogin(){
  // console.log(" >>>>>>>  isLogin , inital:"+initial() +",  userinfo " + !!userInfo +"  islogin " +  initial() && userInfo)
  return initial() && userInfo
}
function refresh() {
  if(!isLogin()) {
    // console.log("   refresh false  isLogin=false" )
    // info()
    return 
  }
  info()
  var currTime = new Date().getTime()
  var expired = getExpiredTime()
  // console.log("  refresh     currTime ", currTime)
  // console.log("  refresh  exipredTime ", expired)
  
  //过期
  if(currTime > expired){
    // console.log("  refresh   过期 expired  currentTime > expiredTime  clear userInfo" )
    // info()
    // debugger
    clearUserInfo()
    return ;
  }
  //过半时间，重置过期时间
  if(currTime > refreshTime){
    // console.log("   过半时间，重置过期时间  pass half expired ,to reset expired time  currTime > refreshTime" )
    // info()
    setExpired()
  }
  // console.log(" 过期时间未过半，直接返回")
  // info()
  //不到一半时间，不需要设置过期时间，直接返回
}
function checkLogin(){
  // console.log(" =======  checklogin " + userInfo)
    let userInfo = getUserInfo();
    // console.log(" =======  getUserInfo ()  " + userInfo)
    // if(userInfo) {
    //   console.log(" =======  getUserInfo ()  " +  userInfo && userInfo.username)
    // }
    // console.log(">>>>   checkLogin : ", userInfo);
    return userInfo && userInfo.username
    // console.log(" jump to /tologin  ")
    // jump.call('/tologin', "push", {title:title});
}

function  getExpiredTime(){
  // console.log("--- getExpiredTime  " +  exipredTime + "  get localStorage: " +  localStorage.getItem(userExipredKey) )

  exipredTime = exipredTime || localStorage.getItem(userExipredKey) || 0
  
  // console.log("   getExpiredTime res  ", exipredTime)
  return exipredTime;
}

function setExpired(){
  var curr = new Date().getTime() 
  exipredTime= curr + expireDuration
  refreshTime = curr + refreshDuration
  // console.log("  set expired " +  exipredTime + "  curr time " + curr)
  localStorage.setItem(userExipredKey, exipredTime)
  info()
}

function getUserInfo(){
  refresh()
  // console.log(" ========  getUserInfo  ", userInfo);
  // info()
  return userInfo;
}


function clearUserInfo(){
  // debugger
  // console.log("  ======= before clearUserInfo ========  ")
  // info()
  localStorage.removeItem(userKey);
  localStorage.removeItem(userExipredKey);
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
  exipredTime = 0
  refreshTime = 0
  doLogin = false
  // console.log("============== set dologin = false")

  userInfo  = null
  // console.log("  ======= after clearUserInfo ========  ")
  // info()
}


function cacheLogin(login) {
  // user_info
  // access_token
  // refresh_token
  // expires_in
  // token_type
  if(login) {
    // console.log("  ======= before  cacheLogin ========  ")
    // info()
    localStorage.setItem(userKey, JSON.stringify(login.user_info));
    localStorage.setItem(tokenKey, JSON.stringify(login.access_token));
    localStorage.setItem(refreshTokenKey, JSON.stringify(login.refresh_token));
    setExpired()
    initialed = false
    doLogin = true
    // console.log("============== set dologin =true")

    initial()
    
  } else {
    console.error("set user info null:", login)
  }

  // info()
}
// 同步操作本地缓存4
function cacheSync(type, key, content) {
  if (!key) {
    key = "";
  }
  if (!content) {
    content = "";
  }
  if (type == "set") {
    // 将 data 存储在本地缓存中指定的 key 中
    return sessionStorage.setItem(key, JSON.stringify(content));
  } else if (type == "get") {
    // 从本地缓存中同步获取指定 key 对应的内容。
    var data = sessionStorage.getItem(key);
    if (data == "") {
      return data;
    } else {
      return JSON.parse(data);
    }
  } else if (type == "clear") {
    sessionStorage.clear();
  }
}
let timeout; //防抖，
// 防抖9
async function debounce(time) {
  return new Promise((resolve) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      resolve();
    }, time);
  });
}
// 进行路由跳转
function jump(path, type, data) {
  // if (this.$route.path != path) {
  if (!data) {
    data = {};
  }
  if (type == "push") {
    //  跳转到新的页面
    this.$router.push({
      path: path,
      query: data,
    });
  } else if (type == "params") {
    //  关闭当前页面
    this.$router.replace({
      name: path,
      params: data,
    });
  } else if (type == "replace") {
    //  关闭当前页面
    this.$router.replace({
      path: path,
      query: data,
    });
  } else if (type == "go") {
    //  页面进行回退
    this.$router.go(path);
  } else if (type == "href") {
    //   跳转到外部链接
    window.location.href = path;
  } else if (type == "down") {
    // console.log(path)
    //   跳转到外部链接
    window.open = path;
  }
}
// }

// 节流10
// var canRun = true;
// async function throttle() {
// 	return new Promise((resolve) => {
// 		if (canRun == true) {
// 			clearTimeout(timerun)
// 			canRun = false
// 			var timerun = setTimeout(() => {
// 				canRun = true;
// 			}, 500);
// 			resolve()
// 		}
// 	})
// }

// 设置当前滚动条高度
function setscrollTop(num) {
  document.documentElement.scrollTop = num;
}



module.exports = {
  refresh,
  checkLogin,
  cacheLogin,
  getUserInfo,
  userInfo,
  info,
  clearUserInfo,
  cacheSync, //对本地缓存的操作
  debounce, //防抖9
  jump,
  // throttle,//节流
  setscrollTop, //设置当前滚动条高度
};
