<template>
  <!-- 当前页面为头部组件 -->
  <div class="hd" >
    <!-- 背景以及logo配置 -->

    <logoback  @logout="onLogout"/>

    <!-- 导航栏(传入数组) -->
   
    <navigation :list="menu" />

      <!-- pc端导航栏 -->
      <!-- <div class="pcnav">
        <navigation :list="list" :pagenum="pagenum" />
      </div>
       <div class="modeionav">
        <modelnav :list="list" :pagenum="pagenum" />
      </div> -->
 
  </div>
</template>

<script>
import logoback from "./logoback"; //背景以及logo配置
import navigation from "./navigation"; //导航栏
// import modelnav from "./modelnav"; //导航栏
const menu_logout = [{
          name: "首页",
          path: "/home",
        },
       {
          name: "新闻公告",
          path: "/news",
          submenu:[{
            name: "新闻信息",
            path: "/list/0",
          },
          {
            name: "公告信息",
            path: "/list/1",
          }]
        },
        {
          name: "检验检测",
          path: "/inspect",
          submenu:[{
            name: "在线检测工具",
            path: "/tool",
          },
          {
            name: "行业应用检测",
            path: "/industry",
          }]
        },
        {
          name: "数据集和算法",
          path: "/dataset",
          submenu:[{
            name: "基础库",
            path: "/list/基础技术领域资源库",
          },
          {
            name: "行业库",
            path: "/list/行业领域资源库",
          },
          {
            name: "专项解决方案",
            path: "/list/专项解决方案资源库",
          }]
        },
        {
          name: "资源中心",
          path: "/resource",
          submenu:[{
            name: "标准",
            path: "/standard",
          },
          {
            name: "知识产权分析报告",
            path: "/patent",
          },
          {
            name: "行业应用案例",
            path: "/case",
          },
          {
            name: "产业分析报告",
            path: "/analyse",
          },
          {
            name: "咨询",
            path: "/consult",
          },
          {
            name: "基础信息",
            path: "/info",
          }]
        },
        {
          name: "在线培训",
          path: "/train",
        },
        {
          name: "成果展示",
          path: "/show",
          submenu:[{
            name: "标准符合性测试",
            path: "/compliance",
          },
          {
            name: "优秀案例",
            path: "/case",
          }]
        }, 
        {
          name: "AISBench",
          path: "/aisbench",
        },
        {
          name: "关于我们",
          path: "/about",
        },
      ]

const menu_login = [{
          name: "首页",
          path: "/home",
        },
       {
          name: "新闻公告",
          path: "/news",
          submenu:[{
            name: "新闻信息",
            path: "/list/0",
          },
          {
            name: "公告信息",
            path: "/list/1",
          }]
        },
        {
          name: "检验检测",
          path: "/inspect",
          submenu:[{
            name: "在线检测工具",
            path: "/tool",
          },
          {
            name: "行业应用检测",
            path: "/industry",
          }]
        },
        {
          name: "数据集和算法",
          path: "/dataset",
          submenu:[{
            name: "基础库",
            path: "/list/基础技术领域资源库",
          },
          {
            name: "行业库",
            path: "/list/行业领域资源库",
          },
          {
            name: "专项解决方案",
            path: "/list/专项解决方案资源库",
          }]
        },
        {
          name: "资源中心",
          path: "/resource",
          submenu:[{
            name: "标准",
            path: "/standard",
          },
          {
            name: "知识产权分析报告",
            path: "/patent",
          },
          {
            name: "行业应用案例",
            path: "/case",
          },
          {
            name: "产业分析报告",
            path: "/analyse",
          },
          {
            name: "咨询",
            path: "/consult",
          },
          {
            name: "基础信息",
            path: "/info",
          }]
        },
        {
          name: "在线培训",
          path: "/train",
        },
        {
          name: "成果展示",
          path: "/show",
          submenu:[{
            name: "标准符合性测试",
            path: "/compliance",
          },
          {
            name: "优秀案例",
            path: "/case",
          }]
        }, 
        {
          name: "我的检测",
          path: "/mytest",
        },
        {
          name: "关于我们",
          path: "/about",
        },
      ]

export default {
  name: "headerbox", //头部样式
  data() {
    return {
      menu: menu_logout,
    }
  },
  props: {
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
    },
  },
  components: {
    logoback, //背景以及logo配置
    navigation, //导航栏
    // modelnav
  },
  created() {
    this.getinfo();
  },
  methods: {
    onLogout(){
      this.getinfo();
    },
    getinfo() {
      //var useinfo = this.until.cacheSync("get", "aitp-userInfo");
      var useinfo = this.until.getUserInfo();
      // console.log(" ---  userinfo ", useinfo)
      // console.log(" ---- userinfo ", useinfo.username)
      
      if (useinfo && useinfo.username != "") {
          this.menu = menu_login
          // var obj = {
          //   name: "我的检测",
          //   path: "/mytest",
          // };
          // // this.list.push(obj);
          // this.menu.push(obj)
      } else {
         this.menu = menu_logout
      }
    },
  },
};
</script>

<style scoped lang="less">
// .hd{
//   border: 2px solid #f00;
// }
// @media screen and (min-width: 1000px) {
//   .modeionav{
//     display: none;
//   }
// }
// @media screen and (max-width: 999px) {
//   .pcnav{
//     display: none;
//   }
// }
</style>
