<template>
  <div class="cnzz ">
    
    <!--
 <span id='cnzz_stat_icon_1280450779'></span>
  
   
<script src='https://s4.cnzz.com/z_stat.php?id=1280450779&show=pic' type='text/javascript'></script>
  
  <script type="text/javascript">document.write(unescape("%3Cspan id='cnzz_stat_icon_1280450779'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1280450779%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"));</script>

  -->
   </div>
 
</template>

 
<script>
document.write(unescape("%3Cspan id='cnzz_stat_icon_1280450779'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1280450779%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"));
export default {
  name: "cnzz", //尾部文字


  created(){
    console.log(" ==============  cnzz created -============")
  },
};
</script>

<style scoped lang="less">
.cnzz {
  color: #939393;
}

</style>
