<template>
  <div id="homebox">
    <!-- 页面宽度 -->
    <div id="pagebox" class="flexcent">
      <div class="flexspb footext">
        <div>中国电子技术标准化研究院 版权所有</div>
        <div>© 2020-2021</div>
        
        <div class="text flexcent">
          <a href="https://beian.miit.gov.cn" target="_blank" class="ba">
            <span>京ICP备05013730号-51</span>
          </a>
    <!--    <img src="../../assets/down.gif" alt="" /> -->
        </div>
        
        <div style="width:300px;margin:0 auto; padding:20px 0;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102005393" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="@/assets/gongan.png" style="float:left;"/>
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010102005393号</p>
          </a>
        </div>
        <cnzz/>
      </div>
    </div>
  </div>
</template>

<script>
import  cnzz from "./cnzz";

export default {
  components: { cnzz },
  name: "footext", //尾部文字

  created() {
    // console.log(" ========== foot created");
  },

  mounted(){
  // console.log(" ========== foot mounted");
  },
  methods: {
    tolink(href) {
      // console.log(href);
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.ba {
  color: #939393;
}

 // 当前页面
  #pagebox {
    // 尾部文字
    .footext {
      width: 1000px;
      height: 150px;
      color: #939393;
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.625rem;
      }
    }
    .text {
      padding-left: 20px;
    }
    .text:hover {
      cursor: pointer;
      color: #ffffff;
    }
  }

// @media screen and (min-width: 1000px) {
 
// }

// @media screen and (max-width: 999px) {
//   // 当前页面
//   #pagebox {
//     background-color: #5d5d5d;
//     // 尾部文字
//     .footext {
//       padding: 1.25rem 0  ;
//       height: 5rem;
//       font-size: 0.875rem;
//       flex-wrap: wrap;
//       flex-direction: column-reverse;
//       div {
//         text-align: center;
//         width: 100%;
//         color: #ffffff;
//       }
//       img {
//         width: 1.25rem;
//         height: 1.25rem;
//         margin-left: 0.625rem;
//       }
//     }
//   }
// }
</style>
