<template>
  <div>
    <div class="topnav">
        <headerbox />
    </div>
    <!-- 首页内容 -->
    <div id="homebox">
      <div id="pagebox" class="contail" >
          <router-view />
      </div>
    </div>
    <!--引入尾部组件 -->
    <div >
      <footerbox />
    </div>
  </div>
</template>

<script>
import headerbox from "@/components/header/headerbox"; //引入头部组件
// import crumbs from "@/components/all/crumbs"; //引入导航栏组件
import footerbox from "@/components/footer/footerbox"; //引入尾部组件

export default {
  name: 'Index',
  // provide() {
  //   return {
  //     Index: this
  //   };
  // },
  components: {
    headerbox, //引入头部组件
    // crumbs, //引入导航栏组件
    footerbox, //引入尾部组件
  },
  data() {
      return {
        // 刷新token锁
        refreshLock: false,
        // 刷新token的时间
        refreshTime: '',
        // 计时器
        timer: ''
      }
    },
  }
  
</script>


<style scoped lang="less">
 @media screen and (min-width: 500px) {  
 
}
@media screen and (max-width: 499px) {

}

// div{
//   border: 2px solid #ee410c;
//   padding: 20px;
// }

// @media screen and (min-width: 1000px) {
//   // 盒子内容
//   .pagebox{
//     min-height: 80vh;
//   }
// }

// @media screen and (max-width: 999px) {
//   .topnav{
//     position: sticky;
//     top: -10rem;
//     z-index: 20;
//   }
// }
</style>
