import { validatenull } from './validate'

export function FormatDate(date, fmt) {
  if(!date)
    return ""
    
  date = new Date(date);
  if (typeof(fmt) === "undefined") {
      fmt = "yyyy-MM-dd HH:mm:ss";
  }
  if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
  }
  for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
      }
  }
  return fmt
} 

/**
 * 根据字典的value显示label
 */
 export const findByvalue = (value, dic) => {
    let result = ''
    if (validatenull(dic)) return value
    if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
      let index = 0
      index = findArray( value, dic)
      if (index !== -1) {
        result = dic[index].label
      } else {
        result = value
      }
    } else if (value instanceof Array) {
      result = []
      let index = 0
      value.forEach(ele => {
        index = findArray( ele, dic)
        if (index !== -1) {
          result.push(dic[index].label)
        } else {
          result.push(value)
        }
      })
      result = result.toString()
    }
    return result
  }

/**
 * 根据字典的value查找对应的index
 */
 export const findArray = ( value, dic) => {
    for (let i = 0; i < dic.length; i++) {
      if (dic[i].value == value) {
        return i
      }
    }
    return -1
  }