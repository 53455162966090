<template>
  <!-- 导航栏(传入数组) -->
  <div id="homebox">
    <!-- 页面宽度 -->
    <div id="pagebox" class="flexspb">
      <!-- logo图 -->
      <div class="flexspb modeimg">
        <!-- <img class="homelogo" src="../../assets/logo.png" /> -->
        <!-- 网站标题 -->
        <div class="website">面向人工智能基础技术及应用的检验检测基础服务平台</div>
      </div>
      <!-- 登录 -->
      <div class="rightbox flexspb" v-if="username == ''">
        <span class="login register flexcent" @click="login"> 请登录 </span>
        <span @click="register" class="register flexcent"> 注册 </span>
      </div>
      <div class="rightbox flexspb welcome" v-if="username != ''">
        <span class="welcome"> 欢迎您，</span>
        <el-dropdown trigger="hover">
          <span class="el-dropdown-link welcome">
            {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user-solid" >
              <router-link to="/user">
                <span>个人信息</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-user-solid" >
              <router-link to="/user/pwd">
                <span>修改密码</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button">
              <span @click="logout">注销登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "logoback", //背景图
  methods: {
    getuserinfo() {
      // var useinfo = this.until.cacheSync("get", "aitp-userInfo");
      var useinfo = this.until.getUserInfo();
      if (useinfo) {
        this.username = useinfo.username;
      }
    },
    login() {
      this.until.jump.call(this, "/login", "push");
    },
    register() {
      this.until.jump.call(this, "/register", "push");
    },
    // 退出登录
    logout() {
      this.username = "";
      this.istrue = false;
      this.$nextTick(() => {
        this.istrue = true;
      });
      this.until.clearUserInfo()
      this.$emit("logout", 0);
      this.until.jump.call(this, "/", "push");
      // this.until.cacheSync("clear");
    },
  },
  data() {
    return {
      username: "",
      path: this.$base.baseUrl, //
      istrue: true,
    };
  },
  created() {
    this.getuserinfo();
  },
};
</script>

<style lang="less">
.welcome {
  color: #eee  !important;
  font-size: 16px;
}
.el-popper[x-placement^="bottom"] {
  margin-top: 0.6rem;
  .popper__arrow {
    top: -0.3rem;
    border-bottom-color: #ffffff;
  }
}
.el-dropdown-menu {
  border-radius: 0.3125rem;
  width: 140px;
  padding: 0;
  box-shadow: 0 0 0.625rem #d1d1d1;
  .popper__arrow {
    border-width: 0.5rem;
  }
  .el-dropdown-menu__item {
    padding: 10px 20px;
    // height: 1.6875rem;
    // line-height: 1.6875rem;
    // font-size: 0.8125rem;
    i {
      margin-right: 10px;
    }
  }
}
</style>

<style scoped lang="less">
#homebox {
    // border: 2px solid #f00;
    background-color: #f9f9f9;
    background: url('../../assets/header.png');
    background-position: center;
    // height: 5rem;
    height: 80px;
  .website {
      color: #FFF;
      font-weight: bold;
    }
}


  
  // 最外层盒子
  #homebox {
  
    // 网站logo
    img {
      // width: 2.1875rem;
      height: 2.1875rem;
    }
    .el-dropdown-link {
      color: #fcb437;
      .el-icon-arrow-down {
        color: #000000;
      }
    }
    // 网站标题
    .website {
      margin-left: 0.625rem;
     
      font-size: 1.5rem;
    }

    // 登录注册
    .rightbox {
      font-size: 0.75rem;
      // 登录
      .login {
        color: #333333;
      }
      // 登录(鼠标移入)
      .login:hover {
        color: #346eda;
      }
      // 注册
      .register {
        width: 3.375rem;
        height: 1.625rem;
        border: 0.0625rem solid #b3d8ff;
        color: #409eff;
        background-color: #ecf5ff;
        margin-left: 0.625rem;
        border-radius: 0.1875rem;
      }
      // 注册(鼠标移入)
      .register:hover {
        color: #ffffff;
        background-color: #409eff;
        border-color: #409eff;
      }
      // 注册(鼠标点击)
      .register:active {
        background-color: #3a8ee6;
      }
    }
    // 登录注册(鼠标移入)
    .rightbox:hover {
      cursor: pointer;
    }
  }
// @media screen and (min-width: 500px) { }

// @media screen and (max-width: 499px) {
  
//   // 最外层盒子
//   #homebox {
//     background-color: #f9f9f9;
//     // height: 5rem;
//     // height: 20px;
//     .modeimg{
//       flex-direction: column;
//       align-items: flex-start;
//       // height: 4.375rem;
//     }
//     .homelogo {
//       display: none;
//     }
//     // 网站logo
//     img {
//       // width: 2.1875rem;
//       height: 2.1875rem;
//       margin-left: 1.25rem;
//     }
//     .el-dropdown-link {
//       color: #fcb437;
//       .el-icon-arrow-down {
//         color: #000000;
//       }
//     }
//     // 网站标题
//     .website {
//       margin-left: 1.25rem;
//       font-size: 1rem;
//       font-weight: 550;
//     }

//     // 登录注册
//     .rightbox {
//       font-size: 0.75rem;
//       // height: 3.125rem;
//       align-items: flex-start;
//       margin-right: 1.25rem;
//       // 登录
//       .login {
//         color: #333333;
//       }
//       // 注册
//       .register {
//        display: none;
//       }
//     }
   
//   } 
// }
</style>
